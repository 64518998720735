setNavbarSize()

function setNavbarSize() {
  const navbarCenter = document.getElementById('navbar-center')
  const navbarRightLarge = document.getElementById('navbar-right-large')
  const navbarRightSmall = document.getElementById('navbar-right-small')
  if (window.innerWidth < 960) {
    navbarCenter.hidden = true
    navbarRightLarge.hidden = true
    navbarRightSmall.hidden = false
  } else {
    navbarRightSmall.hidden = true
    navbarCenter.hidden = false
    navbarRightLarge.hidden = false
  }
}


window.onresize = () => {
  setNavbarSize()
}